<template>
  <b-row>

    <b-col
      v-if="requestCertificates.length"
      md="12"
    >
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="requestCertificates"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveRequestStatusVariant(data.item.status)}`"
            >
              {{ data.item.status }}
            </b-badge>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-center">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.bottom="'Détail'"
                variant="dark"
                size="sm"
                :to="{ name: 'espace-signataire.detail-demande', params: { id: data.item.id } }"
                class="btn-icon rounded-circle ml-1"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.bottom="'Prévisualiser le document signé'"
                v-b-modal.modal-display
                variant="secondary"
                size="sm"
                class="btn-icon rounded-circle ml-1"
                @click.prevent="getPdfFile(data.item)"
              >
                <feather-icon icon="FileTextIcon" />
              </b-button>

            </div>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="requestCertificates.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>
    <b-col
      v-else
      md="12"
    >
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
        <b-card-body>
          <b-row>
            <b-col md="6">
              <b-img
                :src="require('@/assets/images/original.png')"
                class="shadow"
                style="height:200px !important"
                alt=""
                srcset=""
              />
            </b-col>
            <b-col
              md="6"
              class="mt-5"
            >
              <span class="text-center">
                Nous n'avons pas trouvé de résultats à afficher
              </span>
            </b-col>
          </b-row>

        </b-card-body>

      </b-card>
    </b-col>
    <!-- modal -->
    <b-modal
      id="modal-display"
      ref="my-modal"
      size="xl"
      button-size="sm"
      title="Pièce jointe"
      ok-only
      ok-title="Fermer"
    >
      <b-card style="height: 500px;">
        <iframe
          style="height: 500px; width: 100%;"
          :src="PDFURL"
        />
      </b-card>
    </b-modal>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'

import { ref, onMounted } from '@vue/composition-api'
import { required } from '@validations'
import {
  BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BFormInput,
  BSpinner, VBTooltip, VBModal, BBadge, BImg,
} from 'bootstrap-vue'

// eslint-disable-next-line import/no-cycle
import useRequestCertificates from '@/services/requestCertificate/requestCertificate'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BSpinner,
    BBadge,
    BImg,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,

    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const PDFURL = ref('')
    // eslint-disable-next-line no-undef
    const {
      requestCertificates, loader, listRequestCertificatesByMairieAwaitingSignature, requestCertificateProcess, requestCertificateSuccess,
    } = useRequestCertificates()

    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'type_certificat.name', label: 'Type certificat demandé', sortable: true },
      { key: 'demandeur.name', label: 'Impétrant', sortable: true },
      { key: 'created_at', label: 'Date de la demande', sortable: true },
      { key: 'status', label: 'Etat', sortable: true },
      { key: 'mairie_concernee.mairie_name', label: 'Mairie concernée', sortable: true },
      { key: 'actions' },
    ]

    onMounted(async () => {
      await listRequestCertificatesByMairieAwaitingSignature(1)
      // Set the initial number of items
      totalRows.value = requestCertificates.value.length
    })
    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }
    const getPdfFile = item => {
      PDFURL.value = ''
      PDFURL.value = item.signed_file_url
    }
    const resolveRequestStatusVariant = status => {
      if (status === 'Traité') return 'success'
      return 'primary'
    }

    return {
      perPage,
      pageOptions,
      totalRows,
      resolveRequestStatusVariant,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      fields,
      onFiltered,
      requestCertificateProcess,
      requestCertificateSuccess,
      loader,
      requestCertificates,
      getPdfFile,
      PDFURL,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },

}

</script>
